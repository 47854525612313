<template>
  <div class="elv-project-settings-open-api-content">
    <div class="elv-project-settings-open-api-content-document">
      <span @click="openApiDocument"
        ><svgIcon name="document" width="14" height="14" fill="#4674EB" />{{ t('button.APIDocument') }}</span
      >
      {{ t('desc.openApiDocumentTipInfo') }}
    </div>
    <div class="elv-project-settings-open-api-content-title">
      <p>
        {{ t('common.totalKeys') }}:<span>{{ openApiAccessList.length }}</span>
      </p>
      <elv-button height="32" width="87" round plain type="primary" @click="onCreateOpenAPI">
        <SvgIcon name="source-add" width="16" height="16" />{{ t('button.create') }}</elv-button
      >
    </div>
    <div class="elv-project-settings-open-api-content-table-container">
      <CustomTable
        ref="tableListRef"
        border
        stripe
        class="elv-project-settings-open-api-content-table"
        :isShowPagination="false"
        :tableData="openApiAccessList"
        :tableHeader="tableHeader"
        :paginationData="pageParams"
        :tableCountNumber="openApiAccessList.length"
        :tableLoading="tableLoading"
      >
        <template #index="{ index }">
          <span class="elv-data-sources-index">{{ index + 1 }}</span>
        </template>
        <template #entity="{ data }">
          <TextCell :text="data.entity?.name || '-'" :weight="600" size="13px" fontFamily="Plus Jakarta Sans" />
        </template>
        <template #name="{ data }">
          <TextCell :text="data.name || '-'" :weight="400" size="13px" fontFamily="Plus Jakarta Sans" />
        </template>
        <template #key="{ data }">
          <div class="elv-project-settings-open-api-content-item-copy">
            <TextCell
              :text="middleEllipsis(data.apiKey + '', 6) || '-'"
              :weight="400"
              size="13px"
              fontFamily="Barlow"
            />
            <img
              v-if="data.apiKey"
              src="@/assets/img/copy.png"
              alt=""
              class="elv-valuation-price-detail-info__from-content__copy"
              @click.stop="onClickedCopy(data.apiKey)"
            />
          </div>
        </template>
        <template #secret="{ data }">
          <div class="elv-project-settings-open-api-content-item-copy">
            <TextCell
              :text="middleEllipsis(data.apiSecret + '', 6) || '-'"
              :weight="400"
              size="13px"
              fontFamily="Barlow"
            />
            <img
              v-if="data.apiSecret"
              src="@/assets/img/copy.png"
              alt=""
              class="elv-valuation-price-detail-info__from-content__copy"
              @click.stop="onClickedCopy(data.apiSecret)"
            />
          </div>
        </template>
        <template #created="{ data }">
          <TextCell
            :text="dayjs.tz(data?.createdAt, entityStore.entityDetail?.timezone).format('YYYY/MM/DD HH:mm:ss') || '-'"
            :weight="400"
            size="13px"
            fontFamily="Barlow"
          />
        </template>
        <template #detailTag="{ data }">
          <div class="elv-project-settings-open-api-buttons">
            <SvgIcon name="sources-edit" width="18" height="18" fill="#838D95" @click="onEditOpenApi(data)" />
            <SvgIcon name="sources-delete" width="18" height="18" fill="#838D95" @click="onDeleteOpenApi(data)" />
          </div>
        </template>
      </CustomTable>
    </div>
  </div>
  <OpenApiKeyDialog
    v-model="showOpenApiKeyDialog"
    :current-data="currentData"
    :type="openApiKeyDialogType"
    @onCheckDialog="showOpenApiKeyDialog = false"
    @onResetDataList="getOpenApiList"
  />

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :show-cancel-button="true"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('title.deleteAPIKeySecret')"
    :loading="deleteLoading"
    class="elv-openApi-integration-confirm-delete"
    @onConfirmEvent="onConfirmDeleteApi"
    @onCancelEvent="onCancelDeleteApi"
  >
    <template #content>
      <span>{{ t('message.deleteOpenApiKeyTipInfo') }}</span>
    </template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import useClipboard from 'vue-clipboard3'
import { middleEllipsis } from '@/lib/utils'
import timezone from 'dayjs/plugin/timezone'
import BusinessDataApi from '@/api/BusinessDataApi'
import OpenApiKeyDialog from './OpenApiKeyDialog.vue'
import { OpenApiAccessType } from '#/BusinessDataTypes'
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import CustomTable from '../../Project/components/CustomTable.vue'

const route = useRoute()
const { t, locale } = useI18n()
const entityStore = useEntityStore()
const { toClipboard } = useClipboard()

dayjs.extend(utc)
dayjs.extend(timezone)

const tableLoading = ref(false)
const currentData: any = ref({})
const deleteLoading = ref(false)
const deleteMessageBoxRef = ref()
const showOpenApiKeyDialog = ref(false)
const openApiKeyDialogType = ref('add')
const openApiAccessList = ref([] as OpenApiAccessType[])

const pageParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const tableHeader: any[] = [
  {
    dataIndex: 'index',
    title: '',
    slotName: 'index',
    width: 36
  },
  {
    dataIndex: 'entity',
    title: t('common.entity'),
    slotName: 'entity'
  },
  {
    dataIndex: 'name',
    title: t('common.name'),
    slotName: 'name'
  },
  {
    dataIndex: 'key',
    title: t('report.key'),
    slotName: 'key'
  },
  {
    dataIndex: 'secret',
    title: t('report.secret'),
    slotName: 'secret'
  },
  {
    dataIndex: 'created',
    title: t('common.created'),
    slotName: 'created'
  },
  {
    dataIndex: 'detailTag',
    title: '',
    slotName: 'detailTag',
    fixed: 'right',
    width: 80
  }
]

const projectId = computed(() => {
  return String(route.params?.projectId)
})

const openApiDocument = () => {
  const url =
    locale.value === 'en'
      ? 'https://docs.elven.com/v3/openapi/obtaining-api-authorization'
      : 'https://docs.elven.com/v3/chinese/openapi/jie-kou-shou-quan'
  window.open(url, '_blank')
}

const getOpenApiList = async () => {
  try {
    tableLoading.value = true
    const { data } = await ProjectApi.getOpenApiAccessList(projectId.value)
    openApiAccessList.value = data
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

const onCreateOpenAPI = () => {
  openApiKeyDialogType.value = 'add'
  showOpenApiKeyDialog.value = true
}

const onEditOpenApi = (data: any) => {
  currentData.value = data
  openApiKeyDialogType.value = 'edit'
  showOpenApiKeyDialog.value = true
}

const onDeleteOpenApi = (data: any) => {
  currentData.value = data
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onCancelDeleteApi = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDeleteApi = async () => {
  try {
    deleteLoading.value = true
    await BusinessDataApi.deleteOpenApiAccess(currentData.value?.entityId, currentData.value?.openApiAccessId ?? '')
    onCancelDeleteApi()
    ElMessage.success(t('message.deleteSuccess'))
    getOpenApiList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * 复制价格源ID
 * @param msg 复制文本
 */
const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

onMounted(getOpenApiList)
</script>

<style lang="scss" scoped>
.elv-project-settings-open-api-content {
  width: 100%;
  height: calc(100% - 120px);
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  position: relative;

  .elv-project-settings-open-api-content-document {
    display: flex;
    align-items: center;
    height: 42px;
    background-color: #f9fafb;
    margin: 16px 20px;
    padding: 12px 16px;
    color: #636b75;

    span {
      display: flex;
      align-items: center;
      margin-right: 8px;
      color: #1e2024;
      cursor: pointer;

      svg {
        margin-right: 4px;
      }
    }
  }

  .elv-project-settings-open-api-content-title {
    margin: 0px 20px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      display: flex;
      align-items: center;
      color: #838d95;

      span {
        color: #0e0f11;
        margin-left: 4px;
      }
    }
  }

  .elv-project-settings-open-api-content-table-container {
    flex: 1;
    min-height: 0px;
    position: relative;

    .elv-project-settings-open-api-content-table {
      .elv-project-settings-open-api-content-item-copy {
        display: flex;
        align-items: center;

        img {
          width: 14px;
          height: 14px;
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .elv-project-settings-open-api-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 8px;

        svg {
          cursor: pointer;

          &:hover {
            fill: #1343bf;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.elv-openApi-integration-confirm-delete.elv-confirm-info-dialog {
  .el-dialog__header {
    justify-content: center;
  }

  .el-dialog__body {
    padding: 24px 48px;

    span {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }
}
</style>
