<template>
  <div class="elv-project-settings-general-content">
    <div class="elv-project-settings-general-header">
      <p>{{ t('common.entities') }}({{ projectStore?.projectEntityList?.length }})</p>
      <elv-button
        v-if="
          projectStore.projectDetail &&
          projectStore.projectDetail?.usedEntityCount < projectStore.projectDetail?.entityCount
        "
        round
        plain
        type="primary"
        width="119"
        height="32"
        @click="onAddEntity"
      >
        <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addEntity') }}
      </elv-button>
      <!-- <p v-else class="elv-project-settings-general-header__upgrade">
        {{ t('message.needMoreEntities') }}<span @click="onJumpPrice">{{ t('message.upgradePro') }}</span>
      </p> -->
    </div>
    <ul class="elv-project-settings-general-list">
      <li v-for="(entity, index) in projectStore?.projectEntityList" :key="index">
        <div class="elv-project-settings-general-item__user">
          <img v-if="entity.logo" :src="entity.logo" alt="logo" :onerror="useDefaultImage" />
          <img v-else src="@/assets/img/reports/default-entity-logo.png" alt="logo" :onerror="useDefaultImage" />
          <div class="elv-project-settings-general-item__info">
            <p class="elv-project-settings-general-item__info-name">{{ entity.name }}</p>
            <p class="elv-project-settings-general-item__info-email">
              {{ t('project.nav.sources') }}: {{ formatNumber(entity.usedSourceCount) }}
              <!-- ,{{
                t('project.nav.transactions')
              }}: {{ formatNumber(entity.usedTxCount) }} -->
            </p>
          </div>
        </div>
        <div class="elv-project-settings-general-item__operating">
          <div class="elv-project-settings-general-item__operating--button">
            <SvgIcon name="project-setting" width="18" height="18" @click="onEditEntity(entity)" />
            <SvgIcon name="sources-delete" width="18" height="18" @click="onDeleteEntity(entity)" />
          </div>
        </div>
      </li>
    </ul>
  </div>

  <ElvMessageBox
    ref="deleteEntityRef"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('common.deleteEntity')"
    :loading="deleteMemberLoading"
    @onConfirmEvent="onConfirmDeleteEntity"
    @onCancelEvent="onCloseConfirm"
  >
    <template #content>
      <span class="elv-delete-member-confirm-header__title">{{
        t('message.deleteEntityMessage', { name: currentEntityData?.name })
      }}</span></template
    >
  </ElvMessageBox>

  <EntityDialog ref="entityDialogRef" :loading="entityLoading" :model="model" :current-data="currentEntityData" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import EntityApi from '@/api/EntityApi'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import EntityDialog from './EntityDialog.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()

const model = ref('add')
const currentEntityData = ref()
const entityLoading = ref(false)
const deleteMemberLoading = ref(false)
const entityDialogRef = useTemplateRef('entityDialogRef')
const deleteEntityRef = useTemplateRef('deleteEntityRef')

const projectId = computed(() => {
  return String(route.params?.projectId)
})

const onConfirmDeleteEntity = async () => {
  try {
    deleteMemberLoading.value = true
    await EntityApi.deleteProjectEntity(currentEntityData.value?.entityId)
    ElMessage.success(t('message.deleteSuccess'))
    projectStore.fetchEntityList(projectId.value)
    projectStore.fetchProjectDetail(projectId.value)
    deleteEntityRef.value?.onCheckMessageBoxDialog()
    currentEntityData.value = {}
    deleteMemberLoading.value = false
    await globalStore.fetchProjectList()
    menuGlobalStore.menuListInit()
    if (globalStore.projectList.length === 0) {
      router.push({ name: 'landingPage' })
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  } finally {
    deleteMemberLoading.value = false
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onCloseConfirm = () => {
  deleteEntityRef.value?.onCheckMessageBoxDialog()
}

const onEditEntity = async (entityData: any) => {
  currentEntityData.value = {}
  model.value = 'edit'
  entityLoading.value = true
  entityDialogRef.value?.onCheckEntityDialog()
  try {
    await entityStore.fetchEntityDetail(entityData.entityId)
    currentEntityData.value = entityStore.entityDetail
  } catch (error) {
    console.log(error)
  } finally {
    entityLoading.value = false
  }
}

const onAddEntity = () => {
  currentEntityData.value = {}
  model.value = 'add'
  entityDialogRef.value?.onCheckEntityDialog()
}

const onDeleteEntity = (entityData: any) => {
  currentEntityData.value = entityData
  deleteEntityRef.value?.onCheckMessageBoxDialog()
}
</script>

<style lang="scss" scoped>
.elv-delete-member-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2024;
}

.elv-project-settings-general-content {
  width: 572px;
  padding: 0 25px;
  margin-top: 32px;
  padding-bottom: 32px;

  .elv-project-settings-general-header {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0e0f11;
    }

    .elv-project-settings-general-header__upgrade {
      color: #636b75;
      font-size: 12px;
      line-height: auto;
      font-weight: 400;

      span {
        color: #1343bf;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .elv-project-settings-general-list {
    width: 100%;
    border-bottom: 1px solid #edf0f3;
    border-top: 1px solid #edf0f3;

    li {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 68px;
      border-bottom: 1px solid #edf0f3;

      &:last-child {
        border-bottom: none;
      }

      .elv-project-settings-general-item__user {
        display: flex;
        align-items: center;

        img {
          width: 36px;
          height: 36px;
          display: block;
        }
      }

      .elv-project-settings-general-item__info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        .elv-project-settings-general-item__info-name {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #0e0f11;
        }

        .elv-project-settings-general-item__info-email {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #838d95;
        }
      }

      .elv-project-settings-general-item__operating {
        display: flex;
        align-items: center;

        .elv-project-settings-general-item__operating--button {
          display: flex;
          align-items: center;

          svg {
            fill: #838d95;
            cursor: pointer;
            transition: all 0.1s;

            &:last-child {
              margin-left: 16px;
            }

            &:hover {
              fill: #1e2024;
            }
          }
        }

        .elv-project-settings-general-item__operating--role {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 24px;
          border: 1px solid #dde1e6;
          border-radius: 2px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #838d95;
        }
      }
    }
  }
}
</style>
