<template>
  <div v-loading.lock="projectStore.loaded" class="elv-project-settings-container">
    <DetailHeader class="elv-project-setting-info" type="project" />
    <div v-if="isStoppingProject || subscribeStatus === 'EXPIRED'" class="elv-project-setting-locked">
      <ProjectLocked :isLocked="!isStoppingProject" />
    </div>
    <template v-else>
      <div class="elv-project-settings-container__content">
        <div class="elv-project-settings-header">
          <div class="elv-project-settings-header__title">
            {{ t('common.projectSettings') }}
          </div>
          <el-tabs v-model="currentMenu" class="elv-project-settings-header-tab" @tab-click="onCheckMenu">
            <el-tab-pane :label="t('common.general')" name="general" class="elv-report-detail-pane"> </el-tab-pane>
            <el-tab-pane :label="t('common.entities')" name="entities" class="elv-report-detail-pane"> </el-tab-pane>
            <el-tab-pane :label="t('title.settingMembers')" name="members" class="elv-report-detail-pane">
            </el-tab-pane>
            <el-tab-pane :label="t('common.openAPI')" name="openApi" class="elv-report-detail-pane"> </el-tab-pane>
            <!-- <el-tab-pane :label="t('common.integration')" name="integration" class="elv-report-detail-pane"> </el-tab-pane> -->
            <el-tab-pane :label="t('common.security')" name="security" class="elv-report-detail-pane"> </el-tab-pane>
            <el-tab-pane :label="t('common.PlanBilling')" name="plan" class="elv-report-detail-pane"> </el-tab-pane>
          </el-tabs>
        </div>
        <Security v-if="currentMenu === 'security'" :width="headerWidth" />
        <OpenApiView v-else-if="currentMenu === 'openApi'" />
        <div v-else class="elv-project-settings-content">
          <el-scrollbar class="elv-project-settings-general-content">
            <ProjectSettingForm
              v-if="currentMenu === 'general'"
              model="edit"
              :currentEntityData="currentProject"
              :showFormList="showFormList"
            />
            <component :is="currentComponent" v-if="currentComponent" />
          </el-scrollbar>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/lib/utils'
import General from './components/General.vue'
import Members from './components/Members.vue'
import Security from './components/Security.vue'
import OpenApiView from './components/OpenApiView.vue'
import Subscription from './components/Subscription.vue'
import { useGlobalStore } from '@/stores/modules/global'
import Integrations from './components/Integrations.vue'
import DetailHeader from '../components/DetailHeader.vue'
import { useProjectStore } from '@/stores/modules/project'
import ProjectLocked from '@/components/Project/Locked.vue'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { ProjectPermissionLevel, ProjectReviewStatus } from '#/ProjectTypes'
import ProjectSettingForm from '@/components/Project/ProjectSettingForm.vue'

const { t } = useI18n()
const route = useRoute()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()

const currentMenu = ref('general')
const componentMap = {
  entities: General,
  members: Members,
  integration: Integrations,
  plan: Subscription
}

const showFormList = [
  'name',
  'firstName',
  'lastName',
  'companyName',
  'employees',
  'website',
  'jobTitle',
  'email',
  'region'
]

const projectId = computed(() => {
  return String(route.params?.projectId)
})

const headerWidth = computed(() => {
  return isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const subscribeStatus = computed(() => {
  return find(globalStore.projectList, { projectId: projectId.value })?.subscribeStatus
})

const currentProject = computed(() => {
  return find(globalStore.projectList, { projectId: projectId.value })
})

const isStoppingProject = computed(() => {
  return (
    currentProject.value &&
    currentProject.value.plan === ProjectPermissionLevel.FREE &&
    currentProject.value?.projectReview?.status === ProjectReviewStatus.STOP
  )
})

const currentComponent = computed(() => {
  return componentMap[currentMenu.value as keyof typeof componentMap]
})

const onCheckMenu = (menu: any) => {
  if (currentMenu.value === menu.props.name) return
  currentMenu.value = menu.props.name
}

watch(
  () => route.params,
  async () => {
    if (route.params?.projectId === undefined || route.name !== 'project-settings') return
    try {
      if (route?.query?.tab) {
        currentMenu.value = route.query?.tab as string
      } else {
        currentMenu.value = 'general'
      }
      await projectStore.projectInfoInit(projectId.value)
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-project-settings-container {
  width: v-bind('headerWidth');
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .elv-project-setting-info {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    border-bottom: 1px solid #dde1e6;
  }

  .elv-project-settings-header {
    width: 100%;

    .elv-project-settings-header__title {
      margin: 16px 22px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #0e0f11;
    }

    svg {
      fill: #1e2024;
      position: absolute;
      // right: -108px;
      right: 40px;
      top: 4px;
      cursor: pointer;
    }
  }

  .elv-project-settings-header-tab {
    margin-top: 8px;
  }

  .elv-project-settings-content {
    height: calc(100% - 102px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
  }

  .elv-project-settings-container__content {
    flex: 1;
    min-height: 0;
    position: relative;
  }

  .elv-project-setting-locked {
    flex: 1;
    min-height: 0;
    position: relative;
  }
}
</style>

<style>
.elv-layout-main {
  padding: 0 !important;
}
</style>
